import styled from 'styled-components';
import leftBluePoly from '../../images/left-blue-poly.svg';
import rightBluePoly from '../../images/right-blue-poly.svg';

export const GateStyles = styled.div`
  display: flex;
  align-items: center;
  background-color: #001fcc;
  color: var(--white);
  background-image: url(${leftBluePoly}), url(${rightBluePoly});
  background-repeat: no-repeat;
  background-position: top left, right center;
  min-height: 758px;
  @media (max-width: 768px) {
    background-position: top left -300px, right -300px center;
  }

  .lube-container {
    text-align: center;
    margin-top: -56px;
    p.lube-text {
      font-size: 22.5px;
      line-height: 150%;
      letter-spacing: -0.27px;
      color: #f0f1f7;
      margin-top: 30px;

      @media (max-width: 991px) {
        line-height: 135%;
      }
    }

    .header {
      max-width: 770px;
      margin: 0 auto;
    }

    .gate-form {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      text-align: left;
      max-width: 789px;
      margin: 0 auto;
      @media (max-width: 991px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }

      label {
        font-weight: 500;
        font-size: 14.4px;
        line-height: 150%;
        color: #ffffff;
      }

      input {
        max-width: 320px;

        @media (max-width: 991px) {
          margin: 0 auto;
          margin-bottom: 24px !important;
        }
      }
      button,
      button:hover {
        font-weight: bold;
        font-size: 16px;
        line-height: 110%;
        letter-spacing: -0.16px;
        color: var(--white);

        background: #00a081 !important;
        border-radius: 6px;
        min-height: 42px;
        text-transform: none;
        border: none;
        @media (max-width: 991px) {
          margin-top: 16px;
        }
      }
    }
  }
`;

export const LubeMainStyles = styled.div`
  background-color: #001fcc;
  color: var(--white);
  background-image: url(${leftBluePoly}), url(${rightBluePoly});
  background-repeat: no-repeat;
  background-position: top left -200px, right -230px center;
  min-height: 1160px;
  @media (max-width: 991px) {
    min-height: auto;
  }
  @media (max-width: 768px) {
    background-position: top left -320px, right -330px center;
  }

  .lube-container {
    text-align: center;
    padding-top: 24px;
    padding-bottom: 96px;
    .header {
      max-width: 770px;
      color: var(--white);
      margin: 0 auto;
      margin-bottom: 74px !important;
      @media (max-width: 768px) {
        margin-bottom: 40px !important;
      }
      img {
        max-width: 210px;
        margin-bottom: 130px;
      }
      .kicker {
        font-weight: bold;
        font-size: 12px;
        line-height: 120%;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: var(--white);
        margin-bottom: 32px;
      }
      .heading {
        font-weight: bold;
        font-size: 54.93px;
        line-height: 110%;
        letter-spacing: -0.3px;
        color: var(--white);
        margin-bottom: 32px;
        @media (max-width: 768px) {
          font-size: 43.95px;
          line-height: 120%;
        }
      }
      .subhead {
        font-size: 22.5px;
        line-height: 150%;
        letter-spacing: -0.27px;
        color: #f0f1f7;
        margin-bottom: 32px;
      }
    }

    .video-container {
      margin-bottom: 64px;
      &:last-child {
        margin-bottom: 0;
      }
      .heading {
        margin-bottom: 24px;
        font-size: 24px;
        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
      .video-wrapper {
        margin: 0 auto;
        margin-bottom: 24px !important;
        position: relative;
        color: var(--primary-color);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        max-width: 924px;
        max-height: 556px;
        border-radius: 16px;
        .watch-video-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 88px;
          height: 88px;
          border-radius: 50%;
          background-color: var(--white);
          box-shadow: 0 10.5702px 31.7106px -5.2851px rgba(19, 48, 205, 0.1),
            0px 10.5702px 10.5702px -5.2851px rgba(19, 48, 205, 0.04);
          @media (max-width: 768px) {
            width: 56px;
            height: 56px;
          }
          span {
            cursor: pointer;
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 88px;
            @media (max-width: 768px) {
              line-height: 56px;
            }
            svg {
              font-size: 24px;
              margin-bottom: 4px;
              margin-left: 4px;
            }
          }
        }
        .overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.2);
          z-index: 2;
        }
      }
    }

    .helper-text {
      font-style: italic;
      font-weight: normal;
      font-size: 14.4px;
      line-height: 140%;
      color: #fafcfe;
    }
  }
`;
